import React from "react";
import Helmet from "react-helmet";
import { get } from "lodash";

import Layout from "../components/Layout";
import Header from "../components/Header";

import { graphql } from "gatsby";

const ApplyNow = ({ data }) => {
    const slug = get(data, "profile.slug", "");
    const { profile, applyNow, applyNowScript, logIn, gallery } = get(
        data,
        "profile",
        {},
    );
    const followBug = {
        ...profile,
        applyNow,
        applyNowScript,
        logIn,
        slug,
    };
    const dbaLogoBlack = get(data, "profile.profile.branch.dbaLogoBlack", "");
    const header = (
        <Header
            active={[]}
            theme="white-ribbon"
            dbaBlack={dbaLogoBlack}
            clearDba={true}
        />
    );

    /**
     * Determine whether to render iframe or widget
     * @param {String} applyNow
     */
    const renderPage = applyNow => {
        if (
            typeof window !== "undefined" &&
            !window.location.href.startsWith(applyNow)
        )
            window.location.replace(applyNow);

        return null;
    };

    return (
        <Layout
            hasHero={false}
            header={header}
            setBug={true}
            loData={followBug}
            loPhoto={gallery}>
            <Helmet>
                <title>Apply | Bay Equity Home Loans</title>
            </Helmet>
            {renderPage(applyNow)}
        </Layout>
    );
};

export default ApplyNow;

export const pageQuery = graphql`
    query applynow($id: String!) {
        profile(id: { eq: $id }) {
            id
            slug
            applyNow
            applyNowScript
            logIn
            profile {
                name
                email
                jobTitle
                licenseIds {
                    license
                    id
                }
                branch {
                    slug
                    dbaLogoBlack
                    title
                    address1
                    address2
                    address3
                    city
                    state
                    zip
                    lat
                    lon
                }
            }
            gallery {
                photo
                image1
                image2
                image3
            }
            contact {
                phone
                mobilePhone
                fax
                facebook
                instagram
                linkedin
                twitter
                youtube
            }
        }
    }
`;
